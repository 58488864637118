import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Section, Text, Title } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import tjappCover from "../assets/image/tjapp/tjapp-cover.png";
import List from "../components/Core/List";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">ADVERTISING, SECURITY, COMPUTER VISION</Text>
                  <Title className="my-4 text-center">
                    SightHub <br />
                    Analytics for offline Shops
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    SightHub is a project I was co-founder of. The idea of this project was to build an online tool
                    where shops could get a visual of how many people are in their store at any given time. Over time
                    the shop owners could get insights into when people come to their store.
                    <br />
                    <br />
                    The technology used here was a combination of a Raspberry Pi and a camera. The Raspberry Pi would
                    be placed in the shop and the camera would be placed in the entrance. The camera live records everything
                    and is periodically sent to the SightHub server. The server then processes the video, the insights
                    are then synced back to the api.
                    <br />
                    <br />
                    We made an executable pipeline where a frame could enter the system and be processed by different
                    stages. Each stage would do a different operation / task. Such as resizing, turning into a black and white
                    image, other stages could do face detection / face recognition, face tracking. It was super interesting to build a tool
                    that could do all that and process into real data.
                    <br />
                    <br />
                    This project later inspired my graduation project. <strong>Multi-Frame Face Reconstruction With a 3D Morphable Model</strong>
                    But that's a story for another time, ask me about it if you're interested!
                    <br/>
                    <br/>
                  </Text>

                  <Row>
                    <Col lg="8">
                      <Box>
                        <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                          The technologies used here were:
                        </Text>

                        <List>
                          <li>Improving my Python skills</li>
                          <li>Deepening my knowledge of Django</li>
                          <li>Further work with OpenCV / Numpy</li>
                          <li>Learn about: <strong>HOG - Histograms of Oriented Gradients</strong></li>
                          <li>FFMpeg</li>
                        </List>

                        <Text className="py-2" variant="h5">
                          Any further questions? I'd love to tell you more about this project!
                        </Text>
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>

            </Row>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  SightHub
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Start - Until</Text>
                <Title variant="cardBig" className="mt-3">
                  April. 2014 - Feb. 2015
                </Title>
              </Col>
              {/*<Col lg="4" className="d-flex justify-content-lg-end">*/}
              {/*  <Button arrowRight>Live work</Button>*/}
              {/*</Col>*/}
            </Row>
          </Container>
        </div>

        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
      {/*<Section className="mt-lg-5 pb-0">*/}
      {/*  <Container>*/}
        {/*    <Row>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS1} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS2} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">*/}
        {/*        <img src={imgS3} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col lg="6" className="mb-5 pl-lg-4">*/}
        {/*        <img src={imgS4} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Container>*/}
        {/*</Section>*/}

        {/*<Section bg="dark" className="pt-0">*/}
        {/*  <Container>*/}
        {/*    <Row>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS5} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS6} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}

        {/*    <div className="text-center mt-lg-5">*/}
        {/*      <Text variant="tag" className="mb-1" color="lightShade">*/}
        {/*        Next Project*/}
        {/*      </Text>*/}
        {/*      <Link to="portfolio-details">*/}
        {/*        <Button*/}
        {/*          arrowRight*/}
        {/*          className="border-0 bg-transparent shadow-none text-capitalize py-3"*/}
        {/*          css={`*/}
        {/*            font-weight: 700*/}
        {/*            font-size: 1.5rem;*/}
        {/*            letter-spacing: -1.2px;*/}
        {/*            line-height: 1.375;*/}
        {/*            @media ${device.md} {*/}
        {/*              font-size: 2rem;*/}
        {/*            }*/}
        {/*        `}*/}
        {/*        >*/}
        {/*          Replace*/}
        {/*        </Button>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </Container>*/}
        {/*</Section>*/}
    </>
  );
};
export default WorkSingle;
